import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);
import webRouter from './modules/web'
import mobileRouter from './modules/mobile'
const routes_pc = webRouter
const routes_mobile = mobileRouter

var system = {};
var routes = [];
system.pingtai = /(netfront|iphone|midp-2.0|opera mini|ucweb|android|windows ce|symbianos)/i.test(navigator.userAgent);
if (system.pingtai) {
  // routes = routes_mobile
  routes = routes_pc
  console.log('手机')
} else {
  routes = routes_pc
  console.log('PC')
}
console.log(system.pingtai)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
