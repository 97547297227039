const webRouter = [//pc
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/themes/home'),//首页
        children:[
            //page 默认页
            {
                path: '/',
                name: 'homePage',
                component:() => import('@/views/themes/page/homePage.vue'), // 默认页
                meta: {
                    keepAlive: true, 
                    isBack: false
                },
            },
            // {
            //     path: '/manufacturer',
            //     name: 'manufacturer',
            //     component:() => import('@/views/themes/page/manufacturer.vue'), // 厂商
            // },
            // {
            //     path: '/ma_particulars',
            //     name: 'ma_particulars',
            //     component:() => import('@/views/themes/page/ma_particulars.vue'), // 厂商详情
            // },
            // {
            //     path: '/IPbqf',
            //     name: 'IPbqf',
            //     component:() => import('@/views/themes/page/IPbqf.vue'), // IP版权方
            // },
            {
                path: '/entitysj',
                name: 'entitysj',
                component:() => import('@/views/themes/page/entitysj.vue'), // 实体商家
                meta: {
                    keepAlive: true, 
                    isBack: false
                },
            },
            // {
            //     path: '/pa_composer',
            //     name: 'pa_composer',
            //     component:() => import('@/views/themes/page/pa_composer.vue'), // 独立创作人
            // },
            {
                path: '/particulars',
                name: 'particulars',
                component:() => import('@/views/themes/page/particulars.vue'), // 详情
                meta: {
                    keepAlive: true, 
                    isBack: false
                },
            },
            // //login 登录 注册
            // {
            //     path: '/login',
            //     name: 'login',
            //     component:() => import('@/views/themes/login/login.vue'), // 登录
            // },
            // {
            //     path: '/register',
            //     name: 'register',
            //     component:() => import('@/views/themes/login/register.vue'), // 注册
            // },
            // {
            //     path: '/forgetPass',
            //     name: 'forgetPass',
            //     component:() => import('@/views/themes/login/forgetPass.vue'), // 密码重置
            // },
            // //Incubationproject 孵化项目
            // {
            //     path: '/IncubationProject',
            //     name: 'IncubationProject',
            //     component:() => import('@/views/themes/Incubationproject/IncubationProject.vue'), // 孵化项目
            //     meta: {
            //         keepAlive: true, 
            //         isBack: false
            //     },
            // },
            // {
            //     path: '/In_details',
            //     name: 'In_details',
            //     component:() => import('@/views/themes/Incubationproject/In_details.vue'), // 孵化项目详情
            // },
            // //publish 发布/发现
            // {
            //     path: '/publish',
            //     name: 'publish',
            //     component:() => import('@/views/themes/publish/publish.vue'), // 发布/发现
            //     meta: {
            //         keepAlive: true, 
            //         isBack: false
            //     },
            // },
            // //CopyrightTrading 版权交易
            // {
            //     path: '/CopyrightTrading',
            //     name: 'CopyrightTrading',
            //     component:() => import('@/views/themes/CopyrightTrading/CopyrightTrading.vue'), // 版权交易
            //     meta: {
            //         keepAlive: true, 
            //         isBack: false
            //     },
            // },
            // {
            //     path: '/TradingParticular',
            //     name: 'TradingParticular',
            //     component:() => import('@/views/themes/CopyrightTrading/TradingParticular.vue'), // 版权交易详情
            // },
            // //CopyrightRequirements 版权需求
            // {
            //     path: '/CopyrightRequirements',
            //     name: 'CopyrightRequirements',
            //     component:() => import('@/views/themes/CopyrightRequirements/CopyrightRequirements.vue'), // 版权需求
            //     meta: {
            //         keepAlive: true, 
            //         isBack: false
            //     },
            // },
            // {
            //     path: '/Co_Details',
            //     name: 'Co_Details',
            //     component:() => import('@/views/themes/CopyrightRequirements/Co_Details.vue'), // 版权需求详情
            // },
            // //AuthorizeTheCase 授权案例
            // {
            //     path: '/AuthorizeTheCase',
            //     name: 'AuthorizeTheCase',
            //     component:() => import('@/views/themes/AuthorizeTheCase/AuthorizeTheCase.vue'), // 授权案例
            //     meta: {
            //         keepAlive: true, 
            //         isBack: false
            //     },
            // },
            // {
            //     path: '/Au_case',
            //     name: 'Au_case',
            //     component:() => import('@/views/themes/AuthorizeTheCase/Au_case.vue'), // 授权案例详情
            // },
            // //BusinessEntity 个人中心 实体商家
            // {
            //     path: '/BusinessEntity',
            //     name: 'BusinessEntity',
            //     component:() => import('@/views/themes/BusinessEntity/BusinessEntity.vue'), // 实体商家
            // },
            // //IPcopyrigh 个人中心 IP版权方
            // {
            //     path: '/IPcopyrigh',
            //     name: 'IPcopyrigh',
            //     component:() => import('@/views/themes/IPcopyrigh/IPcopyrigh.vue'), // IP版权方
            // },
            // //IPcopyrigh 个人中心 独立创作人
            // {
            //     path: '/Composer',
            //     name: 'Composer',
            //     component:() => import('@/views/themes/Composer/Composer.vue'), // 独立创作人
            // },
        ],
        
    },
]

export default webRouter
